import React, { useState } from 'react';
import { navigate } from 'gatsby';
import MainLayout from '../layouts/mainLayout';
import { Button } from 'react-bootstrap';
import plus from "../assets/svg/minus-circle-solid.svg"
import minus from "../assets/svg/plus-circle-solid.svg"
import question from "../assets/svg/question-circle-solid.svg"

const onPointerClicked = (path: string) => {
  navigate('/faq/' + path);
};

const pointers = [
  {
    qstn: 'How often does my box ship?',
    solution: `Your shipping schedule is setup to match each individual dog’s needs - a box is always 5kgs so big dogs
      will get more regular shipments than small dogs and vice versa. We auto-ship your next box to arrive
      just before your current box runs out.`,
  },
  {
    qstn: 'How often does my payment go out?',
    solution: `Payments go out just before each box ships, so they are on the same schedule as your shipments (i.e.
      tailored to your dogs needs). We let you know 3 days before we process a payment so you know to
      expect it and that your new box is nearly on its way.`,
  },
  {
    qstn: 'How will I know when my next box is due?',
    solution: `The feeding label on your box will tell you when your next box is due. There’s a handy ‘kibble window’
    indicator in your dashboard that predicts how much food you should have left in your box. You can also
    login to your account, view your dashboard and you’ll see your current and next order details.`,
  },
  {
    qstn: 'What if I run out?',
    solution: `Go to your account, view your Dashboard and click the ‘ship today’ button. We’ll get an urgent order
    processed straight away. (Please note ship today orders receive on a weekend will be couriered on
    Monday morning) Your regular schedule will resume from the day that your order is processed - if you
    need to make changes to your delivery schedule you will have the option to change your frequency.`,
  },
  {
    qstn: 'Can I pause or skip my shipments?',
    solution: `Sure! If you’re going away or need a break for some reason, just head over to your Dashboard, scroll to
    see your subscriptions and click "Manage My Order". Within that window you will be able to pause
    your orders.`,
  },
  {
    qstn: 'How do I cancel?',
    solution: `If you need to cancel for any reason, just head over to your Dashboard, scroll to see your subscriptions
    and click "Manage My Order". Within that window you will be able to cancel your orders. We’ll be sad
    to see you go, so if it’s something we did we’d really appreciate an email with feedback so we can fix it
    for others.`,
  },
  {
    qstn: 'My dog is allergic to ‘X’, do you have other meat options?',
    solution: `We have carefully selected all our ingredients for nutritional value and sustainable considerations but if
    there's something that doesn’t fit for your pup’s diet we’d still love to hear from you. Initially we are
    launching with just one highly nutritious dry dog food but we are already working on new flavours and
    varieties, so please drop us a note and tell us what you’d like to see next.`,
  },
  {
    qstn: 'How do I add another dog?',
    solution: `If you’ve got more than one furbaby, you can simply add multiple furbaby profiles in your dashboard.
    View your Dashboard and at the bottom you will see "+Add a new Furbaby".`,
  },
  {
    qstn: 'When will my order ship?',
    solution: `Your First order:new orders received before 4pm will ship the same day on an overnight courier (2-3
      days for South Island).
      Repeat orders: You can see your 'Next Order' date on your feeding label and in your pet's profile.
      Orders are couriered out on your 'Next Order' date and you'll always get an email notification 3 days
      before a scheduled order so that you've got time to check things and make changes if you need. If your
      repeat order day falls on a Saturday or Sunday, it will be couriered Monday morning.`,
  },
  {
    qstn: 'Should I feed once or twice a day?',
    solution: `We’re fans of feeding twice a day but once is fine if that’s what you and your pup prefer. Your feeding
    label shows the total daily requirement so make sure you halve the daily for each meal if you’re feeding
    twice a day.`,
  },
  {
    qstn: 'How do I use my voucher code?',
    solution: `To use your voucher code, you must add your voucher code into the
    My Vouchers section on the dashboard.`,
  },
  {
    qstn: 'How do I make changes to my delivery frequency?',
    solution: `Changing your delivery frequency is simple and can be done at any time. All you need to do is view
    your Dashboard and click on "Manage Orders".`,
  },
  {
    qstn: `How much does shipping Cost?`,
    solution: (
      <>
        We charge a nominal shipping fee to encourage consolidating your pet
        needs in to the same delivery, because it saves us all cost, packaging
        and carbon.
        <br />
        Shipping is $6.99 per order, on a tailored feeding plan with
        auto-delivery - no matter how much is in your order, or where you are in
        NZ*. We recommend setting up an auto delivery for greater convenience,
        best value pricing and cheaper shipping.
        <br />
        If you buy your My Furbaby products as one-off orders from our shop,
        shipping will usually be $8.99 (although it varies from $4.99 for small
        orders and $12.99 for very large orders), and is clearly marked in the
        product listing.
        <br />
        When you buy multiple products, you will only pay one shipping charge
        for the largest item.
        <br />
        We use NZ Post to courier all our deliveries - overnight in North Island
        and Economy (2-3 business days) for South Island. *Rural Deliver is an
        additional charge of $4.53 on all RD couriers - we pass this on from NZ
        Post.
      </>
    ),
    redirect:'/shippingcosts'
  },
];

const changeOrderOptions = [
  {
    title: 'Ship Today Order',
    onPress: () => onPointerClicked('shipToday'),
  },
  {
    title: 'Change Next Order Date',
    onPress: () => onPointerClicked('changeOrderDate'),
  },
  {
    title: 'Change Order Frequency',
    onPress: () => onPointerClicked('changeOrderFrequency'),
  },
  {
    title: 'Pause and Resume Orders',
    onPress: () => onPointerClicked('pauseOrders'),
  },
  {
    title: 'Cancel Subscription',
    onPress: () => onPointerClicked('cancelSubscription'),
  },
  {
    title: 'Reactivating Subscription',
    onPress: () => onPointerClicked('reactivateSubscription'),
  },
];

const modifyAccountOps = [
  {
    title: 'Update Credit Card Details',
    onPress: () => onPointerClicked('updateCCDetails'),
  },
  {
    title: 'Update Delivery Address',
    onPress: () => onPointerClicked('updateDeliveryAddress'),
  },
];

export default () => {
  const [activeQIndexList, setActiveQIndexList] = useState([0]);

  const onQClick = (index: number) => {
    if([12].includes(index)&&pointers[index].redirect){
      navigate(pointers[index].redirect)
      return;
    }
    let newList = [...activeQIndexList];
    const qIndex = newList.indexOf(index);
    const isPresent = qIndex > -1;
    isPresent ? newList.splice(qIndex, 1) : newList.push(index);
    setActiveQIndexList(newList);
  };

  return (
    <MainLayout withTitle>
      <div className="faq-wrapper">
        <div className="faq-content-wrapper">
          <div className="hdr text-left">
            <h2 className="mgn-bot-30">FAQ</h2>
            <h3 className="mgn-bot-20 mgn-top-50">
              Making Changes to your order
            </h3>
            {changeOrderOptions.map(item => (
              <Button
                className="expand-btn mgn-top--5 flex-row mgn-bot-20-imp no-pdd-lt"
                key={item.title}
                onClick={() => item.onPress()}
              >
                {/* <span className="pdd-rt-3">+</span> */}
                <span className="pdd-rt-8"><img height={25} width={25} src={question}/></span>
                <p className="faq-text no-mgn-bot">{item.title}</p>
              </Button>
            ))}
            <h3 className="mgn-bot-20 mgn-top-50">
              Making Changes to your account
            </h3>
            {modifyAccountOps.map(item => (
              <Button
                key={item.title}
                className="expand-btn mgn-top--5 flex-row mgn-bot-20-imp no-pdd-lt"
                onClick={() => item.onPress()}
              >
                <span className="pdd-rt-8"><img height={25} width={25} src={question}/></span>
                <p className="faq-text no-mgn-bot">{item.title}</p>
              </Button>
            ))}
            <h3 className="mgn-bot-20 mgn-top-50">FAQ</h3>
            {pointers.map((item, index) => {
              const isActive = activeQIndexList.indexOf(index) > -1;
              return (
                <div className="mgn-bot-20" key={item.qstn}>
                  <div className="row pos-rel">
                    <Button
                      onClick={() => onQClick(index)}
                      className="expand-btn pos-abs"
                      style={{
                        top: isActive ? '-5px' : '-5px',
                      }}
                    >
                      {!isActive ? <img height={25} width={25} src={minus}/> : <img height={25} width={25} src={plus}/>}
                    </Button>
                    <>
                      <p
                        onClick={() => {
                          onQClick(index);
                        }}
                        className="qnA-text cur-pointer mgn-lft-50"
                      >
                        {item.qstn}
                      </p>
                      {isActive && (
                        <p className="qnA-text mgn-lft-50">{item.solution}</p>
                      )}
                    </>
                  </div>
                </div>
              );
            })}
            <p className="qnA-text">
              Tip: If you're running out of food you might need to increase your
              frequency. If you're finding your box arrives before your current
              box is nearly empty, you might need to decrease your frequency.
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
